<template>
    <el-container class="app-wrapper pc" :lang="$i18n.locale">
        <el-header v-if="headerFixed" class="header-placeholder" height="90">占位</el-header>
        <el-header :class="headerClass" height="120" @touchmove="preventWhenSearchVisible"
                   @mousewheel="preventWhenSearchVisible" @scroll="preventWhenSearchVisible">
            <div class="lang-bar">
                <div class="lang-content">
                    <div class="lang">
                        <span class="icon-language" style="font-size: 24px;"></span>
                        <span class="lang-name">Language</span>
                        <span class="icon-arrow-down"></span>
                        <ul class="lang-list reset">
                            <li @click="setI18nLang('zh-CN')">中文</li>
                            <li @click="setI18nLang('en-US')">English</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div @mouseenter="preventHeaderHide" @mouseleave="() => {timedHideHeader();showHeaderBlock()}" class="content">
                <div class="logo">
                    <router-link :to="'/'" class="flex align-center">
                        <img src="@/assets/logo.png">
                        <div class="name">{{$t('layout.companyName')}}</div>
                    </router-link>
                </div>
                <div class="nav">
                    <ul>
                        <li @mouseenter="showHeaderBlock('products')">
                            <span class="icon-product"></span>
                            <a href="javascript:;">{{$t('layout.productsCenter')}}</a>
                            <em v-if="!visibleHeaderBlock['products']" class="icon-arrow-down"></em>
                            <em v-else class="icon-arrow-up"></em>
                        </li>
                        <li @mouseenter="showHeaderBlock('aboutUs')">
                            <span class="icon-mine"></span>
                            <a href="javascript:;">{{$t('layout.aboutUs')}}</a>
                            <em v-if="!visibleHeaderBlock['aboutUs']" class="icon-arrow-down"></em>
                            <em v-else class="icon-arrow-up"></em>
                        </li>
                        <li class="separator"></li>
                        <li @click="$router.push('/contact-us')">
                            <span class="icon-contact"></span>
                            <a href="javascript:;">{{$t('layout.contactUs')}}</a>
                        </li>
                    </ul>
                </div>
                <!-- 搜索 -->
                <div class="search">
                    <el-input :class="headerSearchVisible ? 'search-input visible' : 'search-input'"
                              v-model="searchKeyword" :placeholder="$t('layout.search')"
                              :autofocus="headerSearchVisible" maxlength="20"
                              @keyup.enter.native="search">
                        <span slot="suffix" @click="search" class="icon-search"></span>
                    </el-input>
                    <span v-if="!headerSearchVisible" @click="showHeaderSearch" class="icon-search"></span>
                    <span v-else @click="headerSearchVisible = false" class="icon-close"></span>
                </div>

                <!-- 产品中心 产品列表 -->
                <div :class="productsClass">
                    <div class="products-content">
                        <div v-for="p in headerItems" v-bind:key="p.itemId" class="product">
                            <div class="image">
                                <aspect-ratio ratio="100" class="overflow-hidden">
                                    <img :src="$resources.autoTransform(p.coverImgUrl)">
                                </aspect-ratio>
                            </div>
                            <div class="product-name">{{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}</div>
                            <router-link class="mask" :key="`/product/product-detail?productId=${p.itemId}`"
                                         :to="`/product/product-detail?productId=${p.itemId}`">
                                <div class="mask-content">{{$t('layout.productDetails')}}</div>
                            </router-link>
                        </div>
                        <router-link to="/product/product-center" :key="`/product/product-center`"
                                     class="product product-more">
                            <div class="more-icon">
                                <aspect-ratio ratio="100">
                                    <svg style="width: 100%;height: 100%;" t="1581995648560" class="icon"
                                         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         p-id="1363" width="200" height="200">
                                        <path
                                            d="M835.8 375c-17.7-41.9-43.1-79.6-75.4-111.9-32.3-32.3-70-57.7-111.9-75.4-43.4-18.4-89.5-27.7-137-27.7s-93.6 9.3-137 27.7c-41.9 17.7-79.6 43.1-111.9 75.4-32.3 32.3-57.7 70-75.4 111.9-18.4 43.4-27.7 89.5-27.7 137s9.3 93.6 27.7 137c17.7 41.9 43.1 79.6 75.4 111.9 32.3 32.3 70 57.7 111.9 75.4 43.4 18.4 89.5 27.7 137 27.7s93.6-9.3 137-27.7c41.9-17.7 79.6-43.1 111.9-75.4 32.3-32.3 57.7-70 75.4-111.9 18.4-43.4 27.7-89.5 27.7-137s-9.4-93.6-27.7-137zM511.4 832c-176.7 0-320-143.3-320-320s143.3-320 320-320 320 143.3 320 320-143.2 320-320 320z"
                                            fill="#bfbfbf" p-id="1364"></path>
                                        <path d="M336 512.2m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#bfbfbf"
                                              p-id="1365"></path>
                                        <path d="M512 512.2m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#bfbfbf"
                                              p-id="1366"></path>
                                        <path d="M688 512.2m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#bfbfbf"
                                              p-id="1367"></path>
                                    </svg>
                                </aspect-ratio>
                            </div>
                            <div class="product-name">{{$t('layout.viewMore')}}</div>
                        </router-link>
                    </div>
                </div>

                <div :class="aboutUsClass">
                    <div class="about-us-content">
                        <div class="info">
                            <div class="info-title">{{$t('layout.aboutUs')}}</div>
                            <router-link to="/company/profile" class="info-line">{{$t('layout.companyProfile')}}
                            </router-link>
                            <router-link to="/company/culture" class="info-line">{{$t('layout.companyCulture')}}
                            </router-link>
                        </div>
                        <div class="info">
                            <div class="info-title">{{$t('layout.companyPhotos')}}</div>
                            <router-link :to="'/photos?type=' + $t('layout.companyHonor')" class="info-line">
                                {{$t('layout.companyHonor')}}
                            </router-link>
                            <router-link :to="'/photos?type=' + $t('layout.companyEnvironment')" class="info-line">
                                {{$t('layout.companyEnvironment')}}
                            </router-link>
                        </div>
                        <div class="info">
                            <div class="info-title">{{$t('layout.news')}}</div>
                            <router-link :to="'/news/news-list?type=' + $t('layout.companyNews')" class="info-line">
                                {{$t('layout.companyNews')}}
                            </router-link>
                            <router-link :to="'/news/news-list?type=' + $t('layout.industryNews')" class="info-line">
                                {{$t('layout.industryNews')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </el-header>

        <!-- 点开搜索时，半透明黑色背景遮罩，阻止事件 -->
        <div v-if="headerSearchVisible" @click="headerSearchVisible = false"
             @touchmove.prevent @mousewheel.prevent class="search-mask"></div>

        <el-main class="main">
            <router-view></router-view>
        </el-main>

        <el-footer class="footer" height="500">
            <div class="content">
                <div class="info-block">
                    <div class="left">
                        <div class="info">
                            <div class="info-title">{{$t('layout.aboutHaoKai')}}</div>
                            <router-link to="/company/profile" class="info-line">{{$t('layout.companyProfile')}}
                            </router-link>
                            <router-link to="/company/culture" class="info-line">{{$t('layout.companyCulture')}}
                            </router-link>
                        </div>
                        <div class="info">
                            <div class="info-title">{{$t('layout.news')}}</div>
                            <router-link :to="'/news/news-list?type=' + $t('layout.companyNews')" class="info-line">
                                {{$t('layout.companyNews')}}
                            </router-link>
                            <router-link :to="'/news/news-list?type=' + $t('layout.industryNews')" class="info-line">
                                {{$t('layout.industryNews')}}
                            </router-link>
                        </div>
                        <div class="info">
                            <div class="info-title">{{$t('layout.productsCenter')}}</div>
                            <router-link v-for="p in footerItems" v-bind:key="p.itemId" class="info-line"
                                         :to="`/product/product-detail?productId=${p.itemId}&product=${p.name}`">
                                {{p.name}}
                            </router-link>
                        </div>
                        <div class="info">
                            <div class="info-title">{{$t('layout.companyPhotos')}}</div>
                            <router-link :to="'/photos?type=' + $t('layout.companyHonor')" class="info-line">
                                {{$t('layout.companyHonor')}}
                            </router-link>
                            <router-link :to="'/photos?type=' + $t('layout.companyEnvironment')" class="info-line">
                                {{$t('layout.companyEnvironment')}}
                            </router-link>
                        </div>
                    </div>
                    <div class="right">
                        <div class="info">
                            <div class="info-title">{{$t('layout.contactUs')}}</div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.address')}}：</div>
                                <div class="info-text" style="max-width: 310px;">{{$t('layout.addressValue')}}</div>
                            </div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.cellTel')}}：</div>
                                <div class="info-text">{{$t('layout.cellTelValue')}}</div>
                            </div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.tel')}}：</div>
                                <div class="info-text">{{$t('layout.telValue')}}</div>
                            </div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.fax')}}：</div>
                                <div class="info-text">{{$t('layout.faxValue')}}</div>
                            </div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.email')}}：</div>
                                <div class="info-text">{{$t('layout.emailValue')}}</div>
                            </div>
                            <div class="info-line">
                                <div class="info-label" style="min-width: 40px;">{{$t('layout.zip')}}：</div>
                                <div class="info-text">{{$t('layout.zipCode')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copy-right-block">
                    <div class="copy-right">CopyRight 2017 All Right Reserved <a href="http://beian.miit.gov.cn">浙ICP备12031803号-1</a></div>
                    <div class="copy-right">{{$t('layout.address')}}：{{$t('layout.addressValue')}}
                        {{$t('layout.cellTel')}}：{{$t('layout.cellTelValue')}}
                        {{$t('layout.fax')}}：{{$t('layout.faxValue')}}
                        {{$t('layout.email')}}：{{$t('layout.emailValue')}}
                    </div>
                </div>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
    import './animations.css';
    import AspectRatio from '../../components/AspectRatio';

    export default {
        components: {
            'aspect-ratio': AspectRatio,
        },

        props: ['items'],

        data() {
            return {
                headerFixed: false,
                headerVisible: true,

                headerSearchVisible: null,
                searchKeyword: null,

                visibleHeaderBlock: {},

                lastScrollTop: 0,

                headerTimer: null,
            }
        },

        created() {
            window.layoutVue = this;
            // 从storage中读取上次选中的语言，如果没有就根据浏览器设置的语言加载
            let locale = localStorage.getItem('locale') || navigator.language;
            if (locale !== 'zh-CN') {
                locale = 'en-US';
            }
            this.$i18n.locale = locale;
        },

        computed: {
            headerClass() {
                let classes = ['header'];
                if (!this.headerVisible) {
                    classes.push('hide');
                }
                if (this.headerFixed) {
                    classes.push('fixed');
                }
                return classes.join(' ');
            },

            productsClass() {
                let classes = ['products'];
                if (!this.visibleHeaderBlock.products) {
                    classes.push('hide');
                }
                return classes.join(' ');
            },

            aboutUsClass() {
                let classes = ['about-us'];
                if (!this.visibleHeaderBlock.aboutUs) {
                    classes.push('hide');
                }
                return classes.join(' ');
            },

            headerItems() {
                if (!this.items || this.items.length <= 0) {
                    return [];
                } else {
                    let items = this.items;
                    if (items && items.length > 7) {
                        items = items.slice(0, 7);
                    }
                    items.forEach(i => {
                        i.name = this.$i18n.locale === 'zh-CN' ? i.zhName : i.enName;
                    });
                    return items;
                }
            },

            footerItems() {
                let items = this.items;
                if (items && items.length > 6) {
                    items = items.slice(0, 6); // 不能用splice 这样this.items就被切割了
                }
                if (items) {
                    items.forEach(i => {
                        i.name = this.$i18n.locale === 'zh-CN' ? i.zhName : i.enName;
                    });
                }
                return items;
            },
        },

        mounted() {
            const $app = document.getElementById('app');
            $app.onscroll = this.onPageScroll;
            $app.onmousewheel = this.preventWhenSearchVisible;
            window.addEventListener('mousewheel', this.preventWhenSearchVisible, {passive: false})
        },

        destroy() {
            // 移除事件
            const $app = document.getElementById('app');
            $app.onscroll = null;
            $app.onmousewheel = null;
            window.removeEventListener('mousewheel', this.preventWhenSearchVisible);
        },

        methods: {
            showHeaderBlock(blockName) {
                Object.keys(this.visibleHeaderBlock).forEach(block => {
                    this.visibleHeaderBlock[block] = block === blockName;
                });

                if (blockName && !this.visibleHeaderBlock[blockName]) {
                    this.visibleHeaderBlock[blockName] = true;
                }

                this.$refreshData(this.visibleHeaderBlock);
            },

            showHeaderSearch() {
                this.showHeaderBlock();
                this.headerSearchVisible = true;
            },

            preventWhenSearchVisible(e) {
                if (this.headerSearchVisible) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                } else {
                    return true;
                }
            },

            search() {
                if (!this.searchKeyword || this.searchKeyword === '') {
                    this.$message.warning('请输入搜索关键字！');
                    return;
                }

                this.headerSearchVisible = false;
                this.$router.push({
                    path: '/product/product-center',
                    query: {
                        keyword: this.searchKeyword
                    }
                });
            },

            onPageScroll() {
                const $app = document.getElementById('app');
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || $app.scrollTop;

                if (scrollTop < this.lastScrollTop) {
                    this.headerVisible = true;
                    this.preventHeaderHide();
                    this.timedHideHeader();
                } else {
                    this.headerVisible = scrollTop <= 200;
                }
                this.lastScrollTop = scrollTop;

                if (scrollTop > 30) {
                    if (!this.headerFixed) {
                        this.headerFixed = true;
                    }
                } else {
                    if (this.headerFixed) {
                        this.headerFixed = false;
                    }
                }
            },

            preventHeaderHide() {
                clearTimeout(this.headerTimer);
            },
            hideHeader() {
                if (this.lastScrollTop > 200) {
                    this.headerVisible = false;
                }
            },
            timedHideHeader() {
                this.headerTimer = setTimeout(this.hideHeader, 3000);
            },

            setI18nLang(lang) {
                localStorage.setItem('locale', lang);
                location.reload();
            },
        }
    }
</script>

<style lang="scss">
    .pc {

        .header {
            width: 100%;
            padding: 0;
            box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
            background-color: var(--white);
            z-index: 10;

            .lang-bar {
                width: 100%;
                height: 30px;
                background-color: var(--black);
                color: var(--white);

                .lang-content {
                    max-width: 1128px;
                    height: 100%;
                    margin: 0 auto;

                    &:after {
                        visibility: hidden;
                        display: block;
                        font-size: 0;
                        content: "";
                        clear: both;
                        height: 0;
                    }

                    .lang {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        height: 100%;
                        cursor: pointer;
                        font-size: 13px;

                        float: right;
                        position: relative;

                        .lang-name {
                            display: inline-block;
                            width: 42px;
                        }

                        span {
                            margin-left: 5px;
                        }

                        .lang-list {
                            display: none;
                            width: 100%;
                            padding: 5px 0 5px 22px;
                            background-color: var(--black);
                            position: absolute;
                            top: 30px;
                            z-index: 10;

                            li {
                                line-height: 20px;
                            }
                        }

                        &:hover .lang-list {
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 100%;
                max-width: 1128px;
                height: 100%;
                margin: 0 auto;

                .logo {
                    img {
                        max-height: 40px;
                        max-width: 133px;
                    }
                    .name {
                        margin-left: 20px;
                        font-size: 20px;
                        font-weight: bolder;
                        @media screen and (max-width: 1080px) {
                            display: none;
                        }
                    }
                }

                .nav {
                    ul {
                        display: flex;
                        margin-right: 30px;
                        @media screen and (max-width: 1080px) {
                            padding-left: 20px;
                            margin-right: 0;
                        }

                        li {
                            display: flex;
                            align-items: center;
                            margin-right: 20px;
                            list-style-type: none;
                            font-size: 20px;

                            &:hover {
                                color: #d81e06;
                                cursor: pointer;
                            }

                            &.separator {
                                width: 2px;
                                height: 40px;
                                background-color: var(--gray);
                            }

                            .icon-product {
                                font-size: 36px;
                            }
                            .icon-mine {
                                font-size: 36px;
                            }
                            .icon-contact {
                                font-size: 36px;
                            }

                            a {
                                margin-left: 5px;
                            }
                        }
                    }
                }

                .search {
                    height: 50px;
                    margin-left: 200px;
                    line-height: 50px;
                    cursor: pointer;
                    position: relative;

                    .search-input {
                        width: 200px;
                        height: 50px;
                        font-size: 16px;
                        line-height: 50px;
                        opacity: 0;
                        position: absolute;
                        right: 25px;
                        top: 0;

                        -webkit-transition: opacity linear .3s;
                        -moz-transition: opacity linear .3s;
                        -ms-transition: opacity linear .3s;
                        -o-transition: opacity linear .3s;
                        transition: opacity linear .3s;
                        transition-property: opacity, right;

                        &.visible {
                            right: 35px;
                            opacity: 1;
                        }

                        .icon-search {
                            padding: 10px;
                            cursor: pointer;
                        }
                    }

                    .icon-search,
                    .icon-close {
                        font-size: 30px;
                    }
                }

                .products,
                .about-us {
                    width: 100%;
                    position: absolute;
                    top: 96px;
                    opacity: 1;
                    background-color: #f0f0f0;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                    transition: opacity linear .3s;
                    transition-property: transform, opacity;

                    &.hide {
                        visibility: hidden;
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                }

                .products {
                    .products-content {
                        display: flex;
                        flex-wrap: wrap;

                        width: 100%;
                        max-width: 1128px;
                        height: 100%;
                        margin: 0 auto;

                        .product {
                            width: 20%;
                            padding: 20px 30px 20px;
                            text-align: center;
                            position: relative;

                            .image {
                                width: 80%;
                                margin: 0 auto;
                                img {
                                    width: 100%;
                                    object-fit: scale-down;
                                }
                            }

                            .product-name {
                                color: var(--black);
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: bold;
                            }

                            &:hover > .mask > .mask-content {
                                visibility: visible;
                            }

                            .mask {
                                display: block;
                                position: absolute;
                                top: 10px;
                                right: 30px;
                                bottom: 20px;
                                left: 30px;

                                .mask-content {
                                    visibility: hidden;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    height: 100%;
                                    font-size: 16px;
                                    color: var(--white);
                                    background-color: rgba(1, 1, 1, .4);
                                }
                            }
                        }

                        .product-more {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .more-icon {
                                width: 80%;
                            }
                            img {
                                max-width: 100px;
                            }
                        }
                    }
                }

                .about-us {
                    .about-us-content {
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;

                        width: 100%;
                        max-width: 1128px;
                        height: 100%;
                        margin: 30px auto;
                        padding-right: 220px;
                    }

                    .info {
                        color: var(--black);
                        text-align: left;
                        &:not(:last-child) {
                            margin-right: 70px;
                        }

                        .info-title {
                            font-size: 20px;
                            font-weight: bolder;
                            line-height: 50px;
                        }
                        .info-line {
                            display: block;
                            font-size: 14px;
                            line-height: 30px;
                            font-weight: 400;
                            position: relative;
                        }
                        .info-line:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

        }

        .header.fixed {
            position: fixed;
            top: 0;
            transition: all ease-in-out .5s;

            .lang-bar {
                display: none;
            }

            .products,
            .about-us {
                top: 66px;
            }
        }

        .header.fixed.hide {
            transform: translateY(-66px);
        }

        .header-placeholder {
            height: 90px;
        }

        .search-mask {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(1, 1, 1, .4);
            z-index: 9;
            animation: fadeIn linear .3s;
        }

        .main {
            padding: 0;
            z-index: 5;
        }

        .footer {
            background-color: #f0f0f0;

            .content {
                width: 100%;
                max-width: 1128px;
                height: 100%;
                margin: 0 auto;

                .info-block {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 40px;

                    .left, .right {
                        display: flex;

                        .info {
                            color: var(--black);
                            text-align: left;
                            &:not(:last-child) {
                                margin-right: 70px;
                            }
                            @media screen and (max-width: 1080px) {
                                &:not(:last-child) {
                                    margin-right: 30px;
                                }
                            }
                            @media screen and (max-width: 840px) {
                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                            }

                            .info-title {
                                font-size: 20px;
                                font-weight: bolder;
                                line-height: 50px;
                            }
                            .info-line {
                                display: flex;
                                font-size: 14px;
                                line-height: 30px;
                                font-weight: 400;
                                &:hover {
                                    text-decoration: underline;
                                }

                                .info-label {
                                    min-width: 80px;
                                }
                                .info-text {
                                    max-width: 250px;
                                }
                            }
                        }
                    }
                }

                .copy-right-block {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 40px;

                    .copy-right {
                        font-size: 12px;
                        line-height: 30px;
                    }
                }
            }

        }
    }
</style>
